import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-4" }
const _hoisted_2 = { class: "fw-500 fs-10px text-inverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: this.pathToRedirect,
      class: "dropdown-item text-decoration-none p-3 bg-none"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("i", {
            class: _normalizeClass([_ctx.icon, "fa-regular h2 opacity-5 d-block my-1"])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}