
import {defineComponent, PropType} from "vue";

export interface NavSubComponent {
    title: string,
    icon: string,
    pathToRedirect: string;
}

export default defineComponent({
    name: "AdminSideBElement",
    props: {
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            require: true
        },
        sub: {
          type: Boolean,
          require: false,
        },
        subElements: {
          type: [] as PropType<NavSubComponent[]>,  
        },
        pathToRedirect: {
            type: String,
            require: true
        }
    },
    data(){
        return {
            toggle: false,
        }
    },
    methods: {
        toggleSub(){
            this.toggle = !this.toggle;
        }
    }
});
