import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24edef70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-icon" }
const _hoisted_2 = { class: "menu-text" }
const _hoisted_3 = {
  key: 0,
  class: "menu-caret"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_admin_side_b_element = _resolveComponent("admin-side-b-element", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.toggleSub())),
      to: this.pathToRedirect,
      class: _normalizeClass([this.sub ? 'sub-padding' : '', "menu-link"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          _createElementVNode("i", {
            class: _normalizeClass([this.icon, "fa-solid"])
          }, null, 2)
        ]),
        _createElementVNode("span", _hoisted_2, _toDisplayString(this.title), 1),
        (this.subElements && this.subElements.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createElementVNode("i", {
                class: _normalizeClass([this.toggle ? 'caret-open' : '', "fa-solid fa-caret-down ms-auto text-theme"])
              }, null, 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to", "class"]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (this.subElements && this.subElements.length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([this.toggle ? 'sub-active' : '', "menu-submenu"])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.subElements, (subElm) => {
                return (_openBlock(), _createBlock(_component_admin_side_b_element, {
                  title: subElm.title,
                  icon: subElm.icon,
                  "path-to-redirect": subElm.pathToRedirect,
                  sub: true
                }, null, 8, ["title", "icon", "path-to-redirect"]))
              }), 256))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}