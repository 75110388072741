
import {defineComponent} from 'vue';
import {mapActions, mapGetters} from 'vuex';
import AlertContainer from "@/components/nav/alert/AlertContainer.vue";
import NavProfileContainer from "@/components/nav/profile/NavProfileContainer.vue";
import NavSearch from "@/components/nav/search/NavSearch.vue";
import NavToolsContainer from "@/components/nav/tools/NavToolsContainer.vue";
import AdminSidebar from "@/components/nav/admin/AdminSideBar.vue";

export default defineComponent({
    name: "NavBar",
    components: {AdminSidebar, NavToolsContainer, NavSearch, NavProfileContainer, AlertContainer},
    data(){
        return {
            sideBar: false,
        }
    },
    methods: {
        ...mapActions(['logout']),
        toggleSideBar(){
            this.sideBar = !this.sideBar;
        }
    },
    computed: {
        ...mapGetters(['isAuth', 'isAdmin'])
    }
})
