
import {defineComponent} from 'vue';
import AdminSideBElement, {NavSubComponent} from "@/components/nav/admin/AdminSideBElement.vue";

export default defineComponent({
    name: "AdminSidebar",
    components: {AdminSideBElement},
    methods: {
    }
});
