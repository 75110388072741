import {createRouter, createWebHashHistory, NavigationGuardNext, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";


function redirectLogin(next: NavigationGuardNext) {
  if(!store.state.auth){
    next({name: "login"});
  } else{
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, from, next) => redirectLogin(next)
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
    beforeEnter: (to, from, next) => redirectLogin(next)
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.auth){
        next({name: "home"});
      } else{
        next();
      }
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "projects" */ '../views/RegisterView.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.auth){
        next({name: "home"});
      } else{
        next();
      }
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/ProjectsView.vue'),
    beforeEnter: (to, from, next) => redirectLogin(next)
  },
  {
    path: '/projects/:id/infos',
    name: 'project-infos',
    component: () => import(/* webpackChunkName: "project-infos" */ '../views/ProjectInfosView.vue'),
    beforeEnter: (to, from, next) => redirectLogin(next)
  },
  {
    path: '/admin',
    name: 'admin-home',
    children: [
      {
        path: '/admin/grank',
        name: 'admin-grank',
        component: () => import(/* webpackChunkName: "admin-grank" */ '../views/admin/AdminGlobalRankView.vue'),
      },
      {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/AdminUserView.vue'),
      },
      {
        path: '/admin/project/types',
        name: 'admin-project-types',
        component: () => import(/* webpackChunkName: "admin-project-type" */ '../views/admin/AdminProjectTypeView.vue'),
      },
      {
        path: '/admin/projects',
        name: 'admin-projects',
        component: () => import(/* webpackChunkName: "admin-projects" */ '../views/admin/AdminProjectView.vue'),
      },
    ] as RouteRecordRaw[],
    component: () => import(/* webpackChunkName: "admin-home" */ '../views/admin/AdminHomeView.vue'),
    beforeEnter: (to, from, next) => redirectLogin(next)
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
