import {createStore} from 'vuex'
import {AdminProfile, HttpClient, LoginModel, RegisterModel, UserResponse} from 'acorewebapisdk4js'
import router from "@/router";


export default createStore({
  state: {
    auth: false,
    admin: false,
    profile: {} as AdminProfile,
  },
  getters: {
    isAuth(state){
      return state.auth;
    },
    isAdmin(state) {
      return state.auth && state.admin;
    },
    getProfile(state){
      return state.profile;
    }
  },
  mutations: {
    setAuth(state, auth: boolean){
      state.auth = auth;
    },
    setAdmin(state, admin: boolean){
      state.admin = admin;
    },
    setProfile(state, profile: AdminProfile){
      state.profile = profile;
    }
  },
  actions: {
    async register({commit}, registerModel: RegisterModel){
      const profile = new AdminProfile(new HttpClient(), () => "");

      await profile.register(registerModel);
      const auth = await profile.isLoggedIn();
      commit('setProfile', profile);
      commit('setAuth', auth);
      commit('setAdmin', false);
      if(auth){
        console.log(profile.user);
        localStorage.user = JSON.stringify(profile.user!);
        if(auth) await router.push({name: "home"});
      }
    },

    async autoLogin({commit}){
      if(localStorage.user){
        const profile = new AdminProfile(new HttpClient(), () => "");

        const user = JSON.parse(localStorage.user);
        console.log(user);
        if(await profile.checkToken(user.token)){
          console.log("Token is valid user is logedIn")
          profile.user = user as UserResponse;
          console.log(profile.user)
          commit('setProfile', profile);
          commit('setAuth', await profile.isLoggedIn());
          commit('setAdmin', await profile.isLoggedInWithAdminAccess());
          await router.push({name: "home"});
        }

        /*console.log("Token found : " + localStorage.token)
        const profile = new AdminProfile(new HttpClient(), () => "");

        commit('setProfile', profile);

        console.log("Auto Loging Profile injected")

        await profile.loginWithToken({access_token: localStorage.token, token_type: "Bearer"} as TokenModel);

        console.log(this.state.profile.user!)

        console.log("isAutoAuth : " + await profile.isLoggedIn());

      */
      }
    },
    async login({commit}, loginModel: LoginModel){

      const profile = new AdminProfile(new HttpClient(), () => "");
      commit('setProfile', profile);
      console.log("Trying Logging")
      try{
        await profile.login(loginModel);
        const auth = await profile.isLoggedIn();
        const admin = await profile.isLoggedInWithAdminAccess();
        console.log("isAuth : " + auth)
        localStorage.user = JSON.stringify(profile.user!);
        commit('setAuth', auth);
        commit('setAdmin', admin);
        if(auth) await router.push({name: "home"});
      }catch (e: any){
        console.log(e);
        commit('setAuth', false);
        commit('setAdmin', false);
      }
    },

    logout({commit, state}){
      if(state.auth){
        state.profile.logout().then(async () => {
          commit('setAdmin', false);
          commit('setAuth', false);
          commit('setProfile', undefined);
          localStorage.removeItem('user');
          await router.push({name: "login"});
        });
      }
    }
  },
  modules: {
  }
})
