<template>
    <div class="menu-item">
        <a href="#" data-toggle-class="app-header-menu-search-toggled" data-toggle-target=".app" class="menu-link">
            <div class="menu-icon">
                <i class="fa-solid fa-magnifying-glass"></i>
            </div>
        </a>
    </div>


 <!-- ???   <form class="menu-search" name="header_search_form">
        <div class="menu-search-container">
            <div class="menu-search-icon"><i class="bi bi-search"></i>
            </div>
            <div class="menu-search-input">
                <input type="text" class="form-control form-control-lg" placeholder="Search menu...">
            </div>
            <div class="menu-search-icon">
                <a href="#">
                    <i class="bi bi-x-lg"></i>
                </a>
            </div>
        </div>
    </form>-->
</template>

<script>
export default {
    name: "NavSearch"
}
</script>

<style scoped>

</style>