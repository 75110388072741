<template>
    <div class="menu-item dropdown dropdown-mobile-full">
        <a href="#" data-bs-toggle="dropdown" class="menu-link">
            <div class="menu-icon">
                <i class="fa-regular fa-bell"></i>
            </div>
            <div class="menu-badge bg-theme"></div>
        </a>

        <div class="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
            <h6 class="dropdown-header fs-10px mb-1">NOTIFICATIONS</h6>
            <div class="dropdown-divider mt-1"></div>
            <!-- content of dropdown alerts -->
            <alert title="test alert" sub_title="test"/>
        </div>
    </div>
</template>

<script>
import Alert from "@/components/nav/alert/Alert.vue";

export default {
    name: "AlertContainer",
    components: {Alert}
}
</script>

<style scoped>

</style>