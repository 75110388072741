
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import NavBar from "@/components/nav/NavBar.vue";

export default defineComponent({
    components: {NavBar},
    methods: {
        ...mapGetters(['isAuth']),
    },

});

