
import {defineComponent} from "vue";

export default defineComponent({
    name: "NavProfileElement",
    props: {
        title: String,
        icon: String
    },
    methods: {
        async handleClick(){
            await this.$emit('nav-click', this);
        }
    }
});
