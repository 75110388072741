import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.handleClick())),
    class: "dropdown-item d-flex align-items-center"
  }, [
    _createTextVNode(_toDisplayString(this.title) + " ", 1),
    _createElementVNode("i", {
      class: _normalizeClass([this.icon, "fa-solid ms-auto text-theme fs-16px my-n1"])
    }, null, 2)
  ]))
}