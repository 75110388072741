
import {defineComponent} from "vue";

export default defineComponent({
    name: "NavToolElement",
    props: {
        title: String,
        icon: String,
        pathToRedirect: String
    }
});
