import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-item dropdown dropdown-mobile-full" }
const _hoisted_2 = {
  href: "#",
  "data-bs-toggle": "dropdown",
  "data-bs-display": "static",
  class: "menu-link"
}
const _hoisted_3 = { class: "menu-img online" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "menu-text d-sm-block d-none w-170px" }
const _hoisted_6 = {
  href: "#",
  class: "dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_profile_element = _resolveComponent("nav-profile-element")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.getThumbnail(),
          alt: "Profile",
          height: "600"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(this.getProfile().user!.email), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_nav_profile_element, {
        title: "Profile",
        icon: "fa-circle-user",
        onNavClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openProfile()))
      }),
      _createVNode(_component_nav_profile_element, {
        title: "Settings",
        icon: "fa-gear"
      }),
      _createVNode(_component_nav_profile_element, {
        title: "Logout",
        icon: "fa-right-from-bracket",
        onNavClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logout()))
      })
    ])
  ]))
}