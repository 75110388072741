
import NavProfileElement from "@/components/nav/profile/NavProfileElement.vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
    name: "NavProfileContainer",
    components: {NavProfileElement},
    methods: {
        ...mapGetters(['getProfile']),
        ...mapActions(['logout']),
        getThumbnail(){
            //console.log(this.getProfile().user!.thumbnail);
            if(this.getProfile().user!.thumbnail && this.getProfile().user!.base64thumbnail){
                console.log(this.getProfile().user!.base64thumbnail)
                return this.getProfile().user!.base64thumbnail;
            }else{
                console.log("defaultLogo")
                return "../../../assets/img/user/profile.jpg";
            }
        },
        async openProfile(){
            await this.$router.push({name: "profile"});
        }
    }

});
