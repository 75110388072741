<template>
    <div class="menu-item dropdown dropdown-mobile-full">
        <a href="#" data-bs-toggle="dropdown" class="menu-link">
            <div class="menu-icon"><i class="fa-solid fa-grip"></i>
            </div>
        </a>
        <div class="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
            <div class="row row-grid gx-0">
                <nav-tool-element title="Projects" icon="fa-hard-drive" path-to-redirect="/projects"/>
                <nav-tool-element title="NewsLetter" icon="fa-envelope" path-to-redirect="/news-letter"/>
                <nav-tool-element title="Tasks" icon="fa-calendar" path-to-redirect="/tasks"/>
            </div>
        </div>
    </div>
</template>

<script>
import NavToolElement from "@/components/nav/tools/NavToolElement.vue";

export default {
    name: "NavToolsContainer",
    components: {NavToolElement}
}
</script>

<style scoped>

</style>