
import {defineComponent} from "vue";

export default defineComponent({
    name: "Alert",
    props: {
        title: String,
        sub_title: String,
    },
    methods: {
        async handleClick(){
            await this.$emit('nav-click', this);
        }
    }
});
